import { Injectable, computed, inject } from "@angular/core";
import type { ChartDataset } from "chart.js";
import type { WidgetModalType } from "src/app/dashboards/custom-dashboards/customDashboard.types";
import { ManageDashboard } from "src/app/dashboards/manageDashboard";
import { ManageLang } from "src/app/languages/services/manageLang";

@Injectable({ providedIn: "root" })
export class LineBarGraphDisplayHandlerUtils {
   private readonly manageLang = inject(ManageLang);
   protected lineBarGraphColors = inject(ManageDashboard).getGraphColors();

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public getGraphColors() {
      return this.lineBarGraphColors;
   }

   public getLabel(
      widgetType: string | false,
      widgetDisplayView: string,
      label?: string,
   ): string {
      switch (widgetType) {
         case "tasks": {
            switch (widgetDisplayView) {
               case "total":
                  if (label) {
                     return `${this.lang().TotalTasks} - ${label}`;
                  }
                  return this.lang().TotalTasks;
               case "plannedVsUnplanned":
                  return label
                     ? `${this.capitalizeFirstLetter(label)} ${this.lang().Tasks}`
                     : "";
               case "ontimeVsOverdueVsCritical":
                  return label
                     ? `${this.capitalizeFirstLetter(label)} ${this.lang().Tasks}`
                     : "";
               case "MTBF":
                  return this.lang().MTBFByHrs;
               case "MTTR":
                  return label
                     ? `${this.lang().MTTRByHrs} - ${label}`
                     : this.lang().MTTRByHrs;
               case "downtimeHours":
                  return label
                     ? `${this.lang().TotalDowntimeByHrs} - ${label}`
                     : this.lang().TotalDowntimeByHrs;
               case "partsCost":
                  return label
                     ? `${this.lang().PartsCost} - ${label}`
                     : this.lang().PartsCost;
               case "laborCost":
                  return label
                     ? `${this.lang().LaborCost} - ${label}`
                     : this.lang().LaborCost;
               case "invoiceCost":
                  return label
                     ? `${this.lang().InvoiceCost} - ${label}`
                     : this.lang().InvoiceCost;
               case "operatingCost":
                  return label ?? "";
               case "timeSpent":
                  return label ? `${this.lang().Hrs} - ${label}` : this.lang().TimeSpent;
               default:
                  return "";
            }
         }
         case "assets": {
            let langValue = "";
            switch (widgetDisplayView) {
               case "assetFieldHistory":
                  return label ?? "";
               case "mtbf":
                  return this.lang().MTBFByHrs;
               case "costParts":
                  langValue = "TotalPartsCost";
                  break;
               case "costTotal":
                  langValue = "TotalOperatingCost";
                  break;
               case "openPms":
                  langValue = "OpenPMs";
                  break;
               case "averageTimeSpent":
               case "completedPlannedWOs":
               case "completedUnplannedWOs":
               case "totalTasks":
               case "totalCompletedTasks":
               case "totalOpenTasks":
               case "totalTimeSpent":
               case "totalUptimeHours":
               case "openWorkRequests":
               case "openUnplannedWOs":
               case "openPlannedWOs":
               case "completedWorkRequests":
                  langValue = this.capitalizeFirstLetter(widgetDisplayView);
                  break;
               default:
                  langValue = "";
            }

            if (!langValue) {
               return "";
            }
            return label
               ? `${this.lang()[langValue]} - ${label}`
               : this.lang()[langValue];
         }
         case "users": {
            switch (widgetDisplayView) {
               case "timeSpent":
                  return label
                     ? `${this.lang().TimeSpent} - ${label}`
                     : this.lang().TimeSpent;
               default:
                  return "";
            }
         }
         case "parts": {
            switch (widgetDisplayView) {
               case "PartsConsumed":
                  return this.lang().PartsConsumed;
               case "CostOfPartsConsumed":
                  return this.lang().CostOfPartsConsumed;
               default:
                  return "";
            }
         }
         case "POs": {
            switch (widgetDisplayView) {
               case "numOfPOs":
                  return this.lang().NumberOfPOs;
               case "costOfPOs":
                  return this.lang().CostOfPOs;
               case "numOfPOItems":
                  return this.lang().NumberOfPOItems;
               default:
                  return "";
            }
         }

         default:
            return "";
      }
   }

   public getHint(widgetType: string | false, widgetDisplayView: string): string {
      switch (widgetType) {
         case "tasks": {
            switch (widgetDisplayView) {
               case "total":
                  return `${this.lang().NumberOf} ${this.lang().Tasks}`;
               case "ontimeVsOverdueVsCritical":
                  return this.lang().CompletedOntimeVsOverdueVsCriticalStatus;
               case "downtimeHours":
                  return this.lang().TotalDowntimeByHrs;
               case "plannedVsUnplanned":
               case "MTBF":
               case "MTTR":
               case "timeSpent":
               case "partsCost":
               case "laborCost":
               case "invoiceCost":
               case "operatingCost": {
                  const langValue = this.capitalizeFirstLetter(widgetDisplayView);
                  return this.lang()[langValue];
               }
               default:
                  return "";
            }
         }
         case "assets":
            switch (widgetDisplayView) {
               case "mtbf": {
                  const langValue = widgetDisplayView.toUpperCase();
                  return this.lang()[langValue];
               }
               case "averageTimeSpent":
               case "totalTasks":
               case "totalCompletedTasks":
               case "totalOpenTasks":
               case "totalTimeSpent":
               case "openWorkRequests":
               case "openUnplannedWOs":
               case "openPlannedWOs":
               case "completedPlannedWOs":
               case "completedUnplannedWOs": {
                  const langValue = this.capitalizeFirstLetter(widgetDisplayView);
                  return this.lang()[langValue];
               }
               case "openPms": {
                  return this.lang().OpenPMs;
               }
               case "costParts": {
                  return this.lang().TotalPartsCost;
               }
               case "costTotal": {
                  return this.lang().TotalOperatingCost;
               }
               default:
                  return "";
            }
         case "users":
            switch (widgetDisplayView) {
               case "timeSpent": {
                  const langValue = this.capitalizeFirstLetter(widgetDisplayView);
                  return this.lang()[langValue];
               }
               default:
                  return "";
            }
         case "parts":
            switch (widgetDisplayView) {
               case "PartsConsumed":
                  return this.lang().PartsConsumed;
               case "CostOfPartsConsumed":
                  return this.lang().CostOfPartsConsumed;
               default:
                  return "";
            }
         case "POs":
            switch (widgetDisplayView) {
               case "numOfPOs":
                  return this.lang().NumberOfPOs;
               case "costOfPOs":
                  return this.lang().CostOfPOs;
               case "numOfPOItems":
                  return this.lang().NumberOfPOItems;
               default:
                  return "";
            }
         default:
            return "";
      }
   }

   public getModalType(
      widgetType: string | false,
      widgetDisplayView: string,
   ): WidgetModalType | undefined {
      switch (widgetType) {
         case "tasks": {
            switch (widgetDisplayView) {
               case "MTBF":
               case "MTTR":
                  return "tasksByDowntime";
               case "partsCost":
               case "laborCost":
               case "invoiceCost":
               case "operatingCost":
                  return "tasksByCost";
               case "plannedVsUnplanned":
                  return "openTasks";
               default:
                  return undefined;
            }
         }
         case "assets":
            switch (widgetDisplayView) {
               case "mtbf":
                  return "tasksByDowntime";
               case "totalTasks":
               case "completedPlannedWOs":
               case "completedUnplannedWOs":
                  return "completedTasks";
               case "openPms":
                  return "openTasks";
               case "averageTimeSpent":
               case "totalTimeSpent":
                  return "tasksByDowntime";
               case "costParts":
               case "costTotal":
                  return "tasksByCost";
               default:
                  return undefined;
            }
         default:
            return undefined;
      }
   }

   public getTitle(
      widgetType: string | false,
      widgetDisplayView: string,
      label: string,
   ): string {
      switch (widgetType) {
         case "tasks": {
            switch (widgetDisplayView) {
               case "operatingCost":
                  return this.lang().OperatingCost;
               case "total":
               case "plannedVsUnplanned":
               case "ontimeVsOverdueVsCritical":
               case "MTBF":
               case "MTTR":
               case "downtimeHours":
               case "partsCost":
               case "laborCost":
               case "invoiceCost":
               case "timeSpent":
                  return label;
               default:
                  return label;
            }
         }
         case "assets": {
            switch (widgetDisplayView) {
               case "mtbf":
               case "averageTimeSpent":
               case "costParts":
               case "costTotal":
               case "totalTasks":
               case "totalCompletedTasks":
               case "totalOpenTasks":
               case "totalTimeSpent":
               case "openWorkRequests":
               case "openUnplannedWOs":
               case "openPlannedWOs":
               case "openPms":
               case "completedPlannedWOs":
               case "completedUnplannedWOs":
               default:
                  return label;
            }
         }
         case "parts": {
            switch (widgetDisplayView) {
               case "PartsConsumed":
                  return this.lang().PartsConsumed;
               case "CostOfPartsConsumed":
                  return this.lang().CostOfPartsConsumed;
               default:
                  return label;
            }
         }
         case "POs": {
            switch (widgetDisplayView) {
               case "numOfPOs":
                  return this.lang().NumberOfPOs;
               case "costOfPOs":
                  return this.lang().CostOfPOs;
               case "numOfPOItems":
                  return this.lang().NumberOfPOItems;
               default:
                  return label;
            }
         }

         default:
            return label;
      }
   }

   public capitalizeFirstLetter(str: string): string {
      return str.charAt(0).toUpperCase() + str.slice(1);
   }

   public roundNumber(num: number, decimalPlaces = 2): number {
      const factor = Math.pow(10, decimalPlaces);
      return Math.round(num * factor) / factor;
   }

   public updateGraphColors(
      dataset: ChartDataset,
      color: string,
      widgetViewedAs: string,
   ): void {
      if (widgetViewedAs === "barGraph") {
         (dataset as ChartDataset<"bar">).backgroundColor = color;
      } else if (widgetViewedAs === "lineGraph") {
         (dataset as ChartDataset<"line">).borderColor = color;
         (dataset as ChartDataset<"line">).pointBackgroundColor = color;
      }
   }

   public getWidgetTypeColorStyles(params) {
      if (
         params.widgetType === "tasks" &&
         params.widgetDisplayView === "ontimeVsOverdueVsCritical" //this widgetType has hardcoded colors.
      ) {
         return [
            this.lineBarGraphColors[2], //green
            this.lineBarGraphColors[5], //yellow
            this.lineBarGraphColors[3], //red
         ];
      }
      return this.lineBarGraphColors;
   }
}
